import * as http from '@/shared/http/index'
import { deleteUserEmailFromCookies } from '@/shared/lib/cookies'
import type { ResponseToken } from './types'

export const refreshToken = () => {
  return http
    .request<ResponseToken>({
      url: `api/v1/cabinet-refresh`,
      method: 'post'
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token)
    })
    .catch(() => {
      deleteUserEmailFromCookies()
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      location.reload()
    })
}
