import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/entities/User/model'
import { UserSpace } from '@/entities/User/types'
import { routes } from '../routes'

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})
router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/login-google', '/login-as']
  const notAllowedForPretestSpaces = [UserSpace.Teacher, UserSpace.Dos] // 'corporate_hr' & 'corporate_student' are not included because they use same routes and pages for testing process
  const authRequired = !publicPages.includes(to.path)
  const auth = useAuthStore()

  // redirect to login page if not logged-in and trying to access a restricted page
  if (authRequired && !auth.loggedIn) {
    auth.returnUrl.path = to.path
    auth.returnUrl.query = to.query

    localStorage.setItem('returnUrl', JSON.stringify({ path: to.path, query: to.query }))
    return next({ path: '/login' })
  }

  if (auth.loggedIn) {
    auth.updateUser()
  }

  // redirect to profile if access to space is denied
  const space: string = to.meta.space as string
  if (space && auth.user) {
    if (Array.isArray(space)) {
      let canNext = false

      for (const spaceItem of space) {
        if (auth.user && auth.user.spaces.includes(spaceItem.toString())) {
          canNext = true
          break
        }
      }

      if (!canNext) {
        return next({ path: '/profile' })
      }
    } else {
      if (!auth.user.spaces.includes(space.toString()) && space !== 'pretest') {
        return next({ path: '/profile' })
      }
    }

    if (
      notAllowedForPretestSpaces.some((space) => auth.user?.spaces.includes(space)) &&
      space === 'pretest'
    ) {
      return next({ path: '/profile' })
    }
  }

  return next()
})

router.onError((error, to) => {
  // Fix when new release is deployed to production and user has old version in cache
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    // @ts-ignore
    window.location = to.fullPath
  }
})
