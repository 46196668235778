<template>
  <ul
    v-if="authStore.loggedIn"
    class="layout-cabinet_header-menu app-scroll-bar"
    :class="{ open: isOpen }"
  >
    <li class="pt-20">
      <router-link
        @click="closeMenu"
        class="layout-cabinet_header-menu-user"
        :to="{ name: 'profile' }"
      >
        <img
          v-if="authStore.user?.avatar?.data.path"
          class="user-logo"
          :src="apiUrl + authStore.user?.avatar?.data.path"
          alt=""
        />
        <img v-else class="user-logo" src="@/shared/images/avatar/avatarIcon.svg" alt="" />
        <span class="p-body sentry-mask">{{ authStore.user?.name }}</span>
      </router-link>
    </li>

    <li v-if="isTeacher || isTutor">
      <router-link
        @click="closeMenu"
        :to="{ name: 'teacher-calendar' }"
        class="layout-cabinet_header-menu-item text-font-heading-display"
        :class="{ active: currentRoute.path.includes('teacher/calendar') }"
      >
        <vue-feather type="calendar" size="14px" stroke="#534E66"></vue-feather>
        <span class="p-body-small">{{ $t('menu.calendar') }}</span>
      </router-link>
    </li>

    <li v-if="isTeacher">
      <router-link
        @click="closeMenu"
        :to="{ name: 'teacher-clubs' }"
        :class="{ active: currentRoute.path.includes('teacher/clubs') }"
        class="layout-cabinet_header-menu-item text-font-heading-display"
      >
        <vue-feather type="message-circle" size="14px" stroke="#534E66"></vue-feather>
        <span class="p-body-small">{{ $t('menu.clubs') }}</span>
      </router-link>
    </li>

    <li v-if="isTeacher">
      <router-link
        @click="closeMenu"
        :to="{ name: 'teacher-substitutions' }"
        class="layout-cabinet_header-menu-item text-font-heading-display"
        :class="{ active: currentRoute.path.includes('teacher/substitutions') }"
      >
        <vue-feather type="repeat" size="14px" stroke="#534E66"></vue-feather>
        <span class="p-body-small">{{ $t('menu.substitutions') }}</span>
      </router-link>
    </li>

    <li v-if="isTeacher">
      <router-link
        @click="closeMenu"
        :to="{ name: 'teacher-statistics' }"
        class="layout-cabinet_header-menu-item text-font-heading-display"
      >
        <vue-feather type="pie-chart" size="14px" stroke="#534E66"></vue-feather>
        <span class="p-body-small">{{ $t('menu.statistics') }}</span>
      </router-link>
    </li>

    <li class="mb-20">
      <ul>
        <li v-if="showCertificates">
          <router-link
            @click="closeMenu"
            :to="{ name: 'profile-certificates' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
          >
            <img
              src="@/shared/images/icons/menu-certificate.svg"
              height="14"
              width="14"
              alt=""
              class="icon"
            />
            <span class="p-body-small">{{ $t('menu.certificates') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            v-if="showTests"
            @click="closeMenu"
            :to="{ name: 'pretest-general' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('/pretests') }"
          >
            <vue-feather type="feather" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.placement_test') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'support' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('/support') }"
          >
            <vue-feather type="phone" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.support') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.CorporateStudent)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display mb-4px">
            {{ $t('corporate_student.menu.corporate_student_space') }}
          </p>
          <p class="p-body-small text-font-primary">{{ authStore.user?.company_name }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'company-student-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('/corporate/student/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-student-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate/student/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-pretest-general' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{
              active:
                currentRoute.path.includes('/pretests') &&
                !currentRoute.path.includes('/corporate-manager')
            }"
          >
            <vue-feather type="feather" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.tests') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-student-clubs' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{
              active: currentRoute.path.includes('corporate/student/clubs/')
            }"
          >
            <vue-feather type="message-circle" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.clubs') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.CorporateHr)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display mb-4px">
            {{ $t('corporate_manager.menu.my_colleagues_space') }}
          </p>
          <p class="p-body-small text-font-primary">{{ authStore.user?.company_name }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-manager-students' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate-manager/students') }"
          >
            <img
              src="@/shared/images/icons/students-hat-grey.svg"
              height="14"
              width="14"
              alt=""
              class="icon"
            />

            <span class="p-body-small">{{ $t('corporate_manager.menu.students_list') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-manager-pretests' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate-manager/pretests') }"
          >
            <vue-feather type="feather" size="14px" stroke="#534E66"></vue-feather>

            <span class="p-body-small">{{ $t('menu.tests') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-manager-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate-manager/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-manager-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate-manager/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-manager-statistics' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('corporate-manager/statistics') }"
          >
            <vue-feather type="dollar-sign" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('corporate_manager.menu.statistics') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.Student)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">{{ $t('menu.student_space') }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'student-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('student/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'student-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('student/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'student-clubs' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('student/clubs') }"
          >
            <vue-feather type="message-circle" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.clubs') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.Parent)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">
            {{ $t('parent-space.menu-header') }}
          </p>
        </li>
        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'parent-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('parent/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'parent-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('parent/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'parent-clubs-general' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
          >
            <vue-feather type="message-circle" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.clubs') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="showTeacherTraining" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">
            {{ $t('menu.teacher_training_space') }}
          </p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-training-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacherTraining/tkt-groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups-tkt') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-webinars' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacherTraining/webinars') }"
          >
            <vue-feather type="monitor" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('events-list.webinars') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-workshops' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacherTraining/workshops') }"
          >
            <vue-feather type="book" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('events-list.workshops') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-conferences' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacherTraining/conferences') }"
          >
            <vue-feather type="grid" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('events-list.conferences') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.Teacher)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">{{ $t('menu.teacher_space') }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'teacher-students-list' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher/students') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.students') }}</span>
          </router-link>
        </li>

        <li v-if="authStore.user?.spaces.includes(UserSpace.Dos)">
          <router-link
            @click="closeMenu"
            :to="{ name: 'dos-groups-feedback' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher/dos') }"
          >
            <vue-feather type="eye" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">DOS</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.CorporateTeacher)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">{{ $t('menu.corporate_space') }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'company-teacher-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher-corporate/groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-teacher-individuals' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher-corporate/individuals') }"
          >
            <vue-feather type="user" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.individuals_lessons') }}</span>
          </router-link>
        </li>

        <router-link
          @click="closeMenu"
          :to="{ name: 'corporate-teacher-pretests-paid' }"
          class="layout-cabinet_header-menu-item text-font-heading-display"
          :class="{ active: currentRoute.path.includes('teacher-corporate/pretests/paid') }"
        >
          <vue-feather type="feather" size="14px" stroke="#534E66"></vue-feather>

          <span class="p-body-small">{{ $t('menu.tests') }}</span>
        </router-link>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'corporate-teacher-students-list' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('teacher-corporate/students') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.students') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li v-if="authStore.user?.spaces.includes(UserSpace.Tutor)" class="mb-20">
      <ul>
        <li class="py-15 px-10">
          <p class="p-body-semi-bold text-font-heading-display">{{ $t('menu.tutor_space') }}</p>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'tutor-tkt-groups' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('tutor/tkt-groups') }"
          >
            <vue-feather type="users" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.groups-tkt') }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            @click="closeMenu"
            :to="{ name: 'tutor-substitutions' }"
            class="layout-cabinet_header-menu-item text-font-heading-display"
            :class="{ active: currentRoute.path.includes('tutor/substitutions') }"
          >
            <vue-feather type="repeat" size="14px" stroke="#534E66"></vue-feather>
            <span class="p-body-small">{{ $t('menu.substitutions') }}</span>
          </router-link>
        </li>
      </ul>
    </li>

    <li class="py-10 cab-border-bottom cab-border-top">
      <LanguageSwitcher></LanguageSwitcher>
    </li>

    <li class="py-10">
      <button
        @click="onLogoutClick"
        class="layout-cabinet_header-menu-item text-font-heading-display w-100"
        type="button"
      >
        <vue-feather type="log-out" size="14px" stroke="#534E66"></vue-feather>
        <span class="p-body-small">{{ $t('menu.log_out') }}</span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useLogoutUser } from '@/entities/User/lib'
import { useAuthStore } from '@/entities/User/model'
import { UserSpace } from '@/entities/User/types'
import { LanguageSwitcher } from '@/shared/ui/language-switchers'

const { currentRoute } = useRouter()
const authStore = useAuthStore()
const { logoutUser } = useLogoutUser()

const emit = defineEmits(['close'])

defineProps({
  isOpen: Boolean,
  isMobile: Boolean
})

const apiUrl = import.meta.env.VITE_API_URL

const notAllowedForPretestSpaces = [
  UserSpace.Tutor,
  UserSpace.Teacher,
  UserSpace.Dos,
  UserSpace.CorporateStudent,
  UserSpace.CorporateHr
]
const showTests = computed(() => {
  return !notAllowedForPretestSpaces.some((space) => authStore.user?.spaces.includes(space))
})

const showCertificates = computed(() => {
  return !(
    authStore.user?.spaces.includes(UserSpace.Tutor) ||
    (authStore.user?.spaces.includes(UserSpace.Teacher) &&
      authStore.user?.spaces.includes(UserSpace.StudentTeacherTraining))
  )
})

const showTeacherTraining = computed(
  () =>
    !authStore.user?.spaces.includes(UserSpace.Teacher) &&
    authStore.user?.spaces.includes(UserSpace.StudentTeacherTraining)
)

const isTeacher = computed(
  () =>
    authStore.user?.spaces.includes(UserSpace.Teacher) ||
    authStore.user?.spaces.includes(UserSpace.CorporateTeacher)
)

const isTutor = computed(() => authStore.user?.spaces.includes(UserSpace.Tutor))

const closeMenu = () => {
  emit('close')
}

const onLogoutClick = () => {
  closeMenu()
  logoutUser()
}
</script>
