export default [
  {
    path: '/teacher/calendar',
    name: 'teacher-calendar',
    meta: {
      space: ['teacher', 'tutor']
    },
    component: () => import('@/modules/teacher/Calendar/pages/CalendarView.vue')
  },
  {
    path: '/teacher/clubs/upcoming',
    name: 'teacher-clubs',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Clubs/pages/ClubsListView.vue')
  },
  {
    path: '/teacher/clubs/archived',
    name: 'teacher-clubs-archived',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Clubs/pages/ClubsListView.vue')
  },
  {
    path: '/teacher/clubs/:id',
    name: 'teacher-clubs-classwork',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Clubs/pages/classwork/ClubClassworkView.vue'),
    children: [
      {
        name: 'teacher-clubs-classwork-summary',
        path: 'summary',
        component: () => import('@/modules/teacher/Clubs/pages/classwork/ClubSummary.vue')
      },
      {
        name: 'teacher-clubs-classwork-notes',
        path: 'notes',
        component: () => import('@/modules/teacher/Clubs/pages/classwork/Notes.vue')
      },
      {
        name: 'teacher-clubs-classwork-zoom-info',
        path: 'zoom-info',
        component: () => import('@/modules/teacher/Clubs/pages/classwork/ZoomInfo.vue')
      }
    ]
  },
  {
    path: '/teacher/substitutions',
    name: 'teacher-substitutions',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Substitution/pages/SubstitutionGroupsView.vue')
  },
  {
    path: '/teacher/substitutions/individuals',
    name: 'teacher-substitutions-individuals',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Substitution/pages/SubstitutionIndividualsView.vue')
  },
  {
    path: '/teacher/substitutions/corporate-groups',
    name: 'teacher-substitutions-corporate',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Substitution/pages/SubstitutionCorporateGroupsView.vue')
  },
  {
    path: '/teacher/substitutions/corporate-individuals',
    name: 'teacher-substitutions-corporate-individuals',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Substitution/pages/SubstitutionCorporateIndividualsView.vue')
  },
  {
    path: '/teacher/groups',
    name: 'teacher-groups',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/List/pages/GroupCoursesListView.vue')
  },
  {
    path: '/teacher/groups/:id',
    name: 'teacher-groups-general',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/General/pages/GroupGeneralView.vue')
  },
  {
    path: '/teacher/groups/:id/schedule',
    name: 'teacher-groups-schedule',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/teacher/groups/:id/schedule/lesson/:lessonId',
    name: 'teacher-groups-schedule-lesson',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Classwork/pages/GroupClassworkView.vue'),
    children: [
      {
        name: 'teacher-groups-schedule-lesson-summary',
        path: 'lesson-summary',
        component: () => import('@/modules/teacher/Groups/Classwork/pages/LessonSummary.vue')
      },
      {
        name: 'teacher-groups-schedule-lesson-notes',
        path: 'notes',
        component: () => import('@/modules/teacher/Groups/Classwork/pages/Notes.vue')
      },
      {
        name: 'teacher-groups-schedule-lesson-zoom-info',
        path: 'zoom-info',
        component: () => import('@/modules/teacher/Groups/Classwork/pages/ZoomInfo.vue')
      },
      {
        name: 'teacher-groups-schedule-lesson-substitution',
        path: 'substitution',
        component: () => import('@/modules/teacher/Groups/Classwork/pages/Substitution.vue')
      }
    ]
  },
  {
    path: '/teacher/groups/:id/feedback',
    name: 'teacher-groups-feedback',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Feedback/pages/GroupFeedbackView.vue')
  },
  {
    path: '/teacher/groups/:id/feedback/:feedbackId',
    name: 'teacher-groups-feedback-by-id',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Feedback/pages/GroupFeedbackStudentView.vue')
  },
  {
    path: '/teacher/groups/:id/feedback/:feedbackId/preview',
    name: 'teacher-groups-feedback-by-id-preview',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Feedback/pages/GroupFeedbackPreviewView.vue')
  },
  {
    path: '/teacher/groups/:id/catch-up',
    name: 'teacher-groups-catch-up',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/CatchUp/pages/GroupCatchUpView.vue')
  },
  {
    path: '/teacher/groups/:id/students',
    name: 'teacher-groups-students',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/Students/pages/GroupStudentsView.vue')
  },
  {
    path: '/teacher/groups/:id/course-summary',
    name: 'teacher-groups-course-summary',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Groups/CourseSummary/pages/GroupCourseSummaryView.vue')
  },
  {
    path: '/teacher/groups/:id/assigned-tasks',
    name: 'teacher-groups-assigned-tasks',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTasksListView.vue')
  },
  {
    path: '/teacher/groups/:id/assigned-tasks/task/:taskId',
    name: 'teacher-groups-assigned-task-students-list',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTasksStudentsView.vue')
  },
  {
    path: '/teacher/groups/:id/assigned-tasks/task/:taskId/:studentId',
    name: 'teacher-groups-assigned-task-by-student-id',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/teacher/groups/assigned-tasks/test/:id/:taskId',
    name: 'teacher-groups-assigned-test',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: '/teacher/groups/assigned-tasks/edit-test/:id/:taskId',
    name: 'teacher-groups-assigned-edit-test',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTestGeneralEditView.vue')
  },
  {
    path: '/teacher/groups/assigned-tasks/student-test/:id/:taskId/:studentId',
    name: 'teacher-groups-assigned-student-test',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Groups/AssignedTasks/pages/AssignedTestStudentView.vue')
  },
  {
    path: '/teacher/dos/groups-feedback',
    name: 'dos-groups-feedback',
    meta: {
      space: 'dos'
    },
    component: () => import('@/modules/teacher/Dos/pages/DosGroupsView.vue')
  },
  {
    path: '/teacher/dos/modules-feedback/:id',
    name: 'dos-modules-feedback',
    meta: {
      space: 'dos'
    },
    component: () => import('@/modules/teacher/Dos/pages/DosModulesView.vue')
  },
  {
    path: '/teacher/dos/:id/feedback/:feedbackId',
    name: 'dos-feedback-content-by-id',
    meta: {
      space: 'dos'
    },
    component: () => import('@/modules/teacher/Dos/pages/DosFeedbackContentView.vue')
  },
  {
    path: '/teacher/individuals',
    name: 'teacher-individuals',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Individuals/List/pages/IndividualsListView.vue')
  },
  {
    path: '/teacher/individuals/schedule/:id',
    name: 'teacher-individuals-schedule',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Individuals/Schedule/pages/IndividualsScheduleView.vue')
  },
  {
    path: '/teacher/individuals/schedule/:id/lesson/:lessonId',
    name: 'teacher-individuals-schedule-lesson',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Individuals/Classwork/pages/IndividualsClassworkView.vue'),
    children: [
      {
        name: 'teacher-individuals-schedule-lesson-summary',
        path: 'lesson-summary',
        component: () => import('@/modules/teacher/Individuals/Classwork/pages/LessonSummary.vue')
      },
      {
        name: 'teacher-individuals-schedule-lesson-notes',
        path: 'notes',
        component: () => import('@/modules/teacher/Individuals/Classwork/pages/Notes.vue')
      },
      {
        name: 'teacher-individuals-schedule-lesson-substitution',
        path: 'substitution',
        component: () => import('@/modules/teacher/Individuals/Classwork/pages/Substitution.vue')
      }
    ]
  },
  {
    path: '/teacher/individuals/general/:id',
    name: 'teacher-individuals-general',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/teacher/Individuals/General/pages/IndividualsGeneralView.vue')
  },
  {
    path: '/teacher/students/:id',
    name: 'teacher-student',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Students/pages/StudentView.vue')
  },
  {
    path: '/teacher/students',
    name: 'teacher-students-list',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Students/pages/StudentListView.vue')
  },
  {
    path: '/teacher/statistics',
    name: 'teacher-statistics',
    meta: {
      space: 'teacher'
    },
    component: () => import('@/modules/teacher/Statistics/pages/StatisticsView.vue')
  }
]
